import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-cross-circle-inverse',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './cross-circle-inverse.component.html',
    styles: [],
})
export class CrossCircleInverseComponent {}
